*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 16px;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body {
    box-sizing: border-box;
    min-height: 100vh;
    max-width: 100vw;
    overflow: hidden auto;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;

}

* {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
}

button button.ltrl_button {
    display: none
}

@media (max-width: 800px) {
    html {
        font-size: 15px;
    }
}

@media (max-width: 500px) {
    html {
        font-size: 14px;
    }
}

@font-face {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: normal;
    src: local('Circular Std Book'), url(assets/fonts/CircularStd-Book.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    font-style: italic;
    font-weight: normal;
    src: local('Circular Std Book Italic'), url(assets/fonts/CircularStd-BookItalic.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    src: local('Circular Std Medium'), url(assets/fonts/CircularStd-Medium.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    font-style: italic;
    font-weight: 500;
    src: local('Circular Std Medium Italic'), url(assets/fonts/CircularStd-MediumItalic.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: bold;
    src: local('Circular Std Bold'), url(assets/fonts/CircularStd-Bold.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    font-style: italic;
    font-weight: bold;
    src: local('Circular Std Bold Italic'), url(assets/fonts/CircularStd-BoldItalic.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 900;
    src: local('Circular Std Black'), url(assets/fonts/CircularStd-Black.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    font-style: italic;
    font-weight: 900;
    src: local('Circular Std Black Italic'), url(assets/fonts/CircularStd-BlackItalic.woff) format('woff');
    font-display: swap;
}
