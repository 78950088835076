.MuiSnackbarContent-root.wu-snackbar-success {
  background-color: #2f73d8;
}

.MuiSnackbarContent-root.wu-snackbar-error {
  background-color: rgb(245, 49, 68);
}

.MuiSnackbarContent-root.wu-snackbar-info {
  background-color: #666666;
}